import "./App.css";
// import Home from "./Home/Home";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AllRoutes from "./Routes/AllRoutes";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App;
