import React from "react";
import { Routes, Route } from "react-router-dom";
import JoinTheClub from "../pages/JoinTheClub/JoinTheClub";
import Home from "../Home/Home";
import Check from "../pages/JoinTheClub/check/Check";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<JoinTheClub />} />
        <Route path="/homeabhi9331553831" element={<Home />} />
        <Route path="/check" element={<Check />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
