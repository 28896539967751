import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import loginIcon from "../Home/images/login.svg";
import contactIcon from "../Home/images/contact.svg";
import Hamburger from "hamburger-react";
import LoginIcon from "../Home/utils/LoginIcon";
import ContactIcon from "../Home/utils/ContactIcon";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
      if (window.innerWidth > 1150) {
        setOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenu = () => {
    setOpen(!isOpen);
  };

  return (
    <nav id="navbar">
      <div className="navbar-content">
        <div className="logo">
          <Link to="/">
            <img src="/images/LOGO.svg" alt="logo" />
          </Link>
        </div>

        {isMobile && (
          <div className="hamburger">
            <Hamburger toggled={isOpen} toggle={handleMenu} />
          </div>
        )}

        <div
          className={`menu-container ${isOpen ? "open" : ""} ${
            isMobile ? "mobile" : ""
          }`}
        >
          <ul className="menu">
            <li>
              <Link to="/strategy">Strategy</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/product">Product</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/frequently-asked-questions">FAQ</Link>
            </li>
          </ul>

          {isMobile && (
            <div className="actions">
              <button className="login">
                Login
                <img src={loginIcon} alt="login_icon" />
              </button>
              <button className="talk-to-us">
                Talk to us
                <img src={contactIcon} alt="contact_icon" />
              </button>
            </div>
          )}
        </div>

        {!isMobile && (
          <div className="actions">
            <button className="login">
              Login
              <LoginIcon />
            </button>
            <button
              className="talk-to-us"
              onClick={() => (window.location.href = "tel:+919902370041")}
            >
              Talk to us
              <ContactIcon />
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
