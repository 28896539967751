import React from 'react'
import Navbar from '../../../Navbar/Navbar'

const Check = () => {
  return (
    <div style={{backgroundColor:"black"}}>
      <Navbar />
    </div>
  )
}

export default Check


